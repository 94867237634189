import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

Chart.register(ArcElement, Tooltip, Legend);

const PieChart = ({ data }) => {
  return (
    <div style={{ height: '400px', width: '100%' }}> {/* Set height explicitly */}
      <Pie 
        data={data} 
        options={{
          responsive: true,
          maintainAspectRatio: false, 
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Pie Chart Example',
            },
          },
        }} 
      />
    </div>
  );
};

export default PieChart;
