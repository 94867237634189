import React from 'react';
import { Box, Text, SimpleGrid } from '@chakra-ui/react';
import LineChart from './Components/LineChart/LineChart.js'; // Assuming you have a LineChart component
import BarChart from './Components/BarChart/BarChart.js'; // Assuming you have a BarChart component
import PieChart from './Components/PieChart/PieChart.js'; // Assuming you have a PieChart component

const Leads = () => {

  return (
    <Box p={5}>
      <Text fontSize="2xl" fontWeight="bold" mb={5} color="yellow">
        Leads - Visualizações e Pedidos
      </Text>

      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={5} mb={5}>
        {/* Visualizações Gerais */}
        <Box bg="gray.700" p={5} borderRadius="md">
          <Text color="yellow" mb={5}>Visualizações Gerais</Text>
          <LineChart 
            data={{
              labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
              datasets: [
                {
                  label: 'Visualizações',
                  data: [30, 50, 100, 75, 200, 150, 300],
                  borderColor: '#4A90E2',
                  backgroundColor: 'rgba(74,144,226,0.1)',
                }
              ]
            }} 
          />
        </Box>

        {/* Visualizações por Usuário */}
        <Box bg="gray.700" p={5} borderRadius="md">
          <Text color="yellow" mb={5}>Visualizações por Usuário</Text>
          <BarChart 
            data={{
              labels: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
              datasets: [
                {
                  label: 'Visualizações',
                  data: [50, 100, 80, 150, 200],
                  backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#66BB6A', '#EF5350'],
                }
              ]
            }} 
          />
        </Box>
      </SimpleGrid>

      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={5} mb={5}>
        {/* Pedidos Feitos */}
        <Box bg="gray.700" p={5} borderRadius="md">
          <Text color="yellow" mb={5}>Pedidos Feitos</Text>
          <LineChart 
            data={{
              labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
              datasets: [
                {
                  label: 'Pedidos Feitos',
                  data: [10, 20, 30, 40, 50, 60, 70],
                  borderColor: '#F44336',
                  backgroundColor: 'rgba(244,67,54,0.1)',
                }
              ]
            }} 
          />
        </Box>

        {/* Pedidos Efetuados */}
        <Box bg="gray.700" p={5} borderRadius="md">
          <Text color="yellow" mb={5}>Pedidos Efetuados</Text>
          <PieChart 
            data={{
              labels: ['Completos', 'Pendentes', 'Cancelados'],
              datasets: [
                {
                  label: 'Pedidos Efetuados',
                  data: [60, 30, 10],
                  backgroundColor: ['#66BB6A', '#FFCE56', '#EF5350'],
                }
              ]
            }} 
          />
        </Box>
      </SimpleGrid>

    </Box>
  );
};

export default Leads;
