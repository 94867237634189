import React, { useState } from 'react';
import { 
  ChakraProvider, 
  Box, 
  SimpleGrid, 
  Text, 
  Flex, 
  Button, 
  Switch, 
  Tabs, 
  TabList, 
  Tab, 
  TabPanels, 
  TabPanel 
} from '@chakra-ui/react';
import Leads from './Leads';
import GeographyChartMock from './Components/MapMock'; 
import GeographyChart from './Components/Map'; 
import TransactionsMock from './Components/SalesTable/TransactionMock'; 
import Transactions from './Components/SalesTable/Transaction'; 
import LineChartMock from './Components/LineChart/LineChartMock'; 
import LineChart from './Components/LineChart/LineChart'; 
import SellerTransactionsMock from './Components/SellersChart/SellerTableMock'; 
import SellerTransactions from './Components/SellersChart/SellerTable'; 
import StackedBarChartMock from './Components/SellersChart/StackedBarChartMock'; 
import StackedBarChart from './Components/SellersChart/StackedBarChart'; 

const Dashboard = () => {
  // State to track whether to use mock data or real data
  const [useMock, setUseMock] = useState(true); // Start with mock by default

  const barChartData = {
    labels: ['AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM'],
    datasets: [
      {
        label: 'Donut',
        data: [100, 150, 200, 250, 300, 350, 400],
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
      },
      {
        label: 'Fries',
        data: [200, 250, 300, 350, 400, 450, 500],
        backgroundColor: 'rgba(54,162,235,0.2)',
        borderColor: 'rgba(54,162,235,1)',
        borderWidth: 1,
      },
      {
        label: 'Kebab',
        data: [150, 200, 250, 300, 350, 400, 450],
        backgroundColor: 'rgba(255,206,86,0.2)',
        borderColor: 'rgba(255,206,86,1)',
        borderWidth: 1,
      },
    ],
  };

  const getRandomNumber = (min, max) => {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber.toLocaleString('pt-BR'); 
  };

  const getRandomPercentage = (min, max) => {
    return (Math.random() * (max - min) + min).toFixed(2);
  };

  const randomPercentage = `+${getRandomPercentage(5, 75)}%`; 

  const getRandomCurrency = (min, max) => {
    const randomValue = getRandomNumber(min, max);
    return `R$ ${randomValue.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`;
  };

  const randomCurrency = getRandomCurrency(10000, 50000); 

  return (
    <ChakraProvider>
      <Box p={0}>

        {/* Tabs for Dashboard and Leads */}
        <Tabs variant="enclosed" colorScheme="blue">
          <TabList>
            <Tab>Dashboard</Tab>
            <Tab>Leads</Tab>
          </TabList>

          <TabPanels>
            {/* Dashboard Content */}
            <TabPanel>
              <Flex justify="space-between" align="center" mb={5}>
                <Text fontSize="2xl" fontWeight="bold">DASHBOARD</Text>

                <Flex align="center">
                  <Text mr={2}>Modo Exemplo dos Gráficos</Text>
                  <Switch
                    colorScheme="blue"
                    isChecked={useMock}
                    onChange={() => setUseMock((prev) => !prev)} 
                  />
                </Flex>

                <Button colorScheme="blue">Download</Button>
              </Flex>

              <SimpleGrid columns={{ sm: 2, md: 4 }} spacing={5} mb={5}>
                <Box bg="gray.700" p={5} borderRadius="md">
                  <Text color="yellow">Quantidade de Clientes</Text>
                  <Text color="white" fontSize="2xl">{getRandomNumber(10000, 30000)}</Text>
                  <Text color="green.400">{randomPercentage}</Text>
                </Box>
                <Box bg="gray.700" p={5} borderRadius="md">
                  <Text color="yellow">Quantidade de Vendas</Text>
                  <Text color="white" fontSize="2xl">{getRandomNumber(100000, 500000)}</Text>
                  <Text color="green.400">{randomPercentage}</Text>
                </Box>
                <Box bg="gray.700" p={5} borderRadius="md">
                  <Text color="yellow">Volume de Vendas</Text>
                  <Text color="white" fontSize="2xl">{randomCurrency}</Text>
                  <Text color="green.400">{randomPercentage}</Text>
                </Box>
                <Box bg="gray.700" p={5} borderRadius="md">
                  <Text color="yellow">Ticket Médio de vendas</Text>
                  <Text color="white" fontSize="2xl">{getRandomNumber(500000, 2000000)}</Text>
                  <Text color="green.400">{randomPercentage}</Text>
                </Box>
              </SimpleGrid>

              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={5} mb={5}>
                <Box bg="gray.700" p={5} borderRadius="md">
                  <Text color="yellow" mb={5}>Volume</Text>
                  {useMock ? <LineChartMock /> : <LineChart />}
                </Box>
                <Box bg="gray.700" p={5} borderRadius="md">
                  {useMock ? <TransactionsMock /> : <Transactions />}
                </Box>
              </SimpleGrid>

              <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={5} mb={5}>
                <Box bg="gray.700" p={2} borderRadius="md">
                  <Text color="yellow" px={4} pt={3}>Rankings Vendedores</Text>
                  {useMock ? <SellerTransactionsMock /> : <SellerTransactions />}
                </Box>
                <Box bg="gray.700" p={2} borderRadius="md">
                  <Text color="yellow" px={4} pt={3}>Quantidade de Vendas</Text>
                  {useMock ? <StackedBarChartMock /> : <StackedBarChart />}
                </Box>
              </SimpleGrid>

              <Box bg="gray.700" p={5} borderRadius="md">
                <Text color="yellow" mb={5}>Volume de Vendas em Nível Nacional - Mapa</Text>
                {useMock ? <GeographyChartMock /> : <GeographyChart />}
              </Box>
            </TabPanel>

            {/* Leads Content */}
            <TabPanel>
              <Text fontSize="2xl" fontWeight="bold" mb={5}>Leads</Text>
              <Box bg="gray.700" p={5} borderRadius="md">
                <Leads />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </ChakraProvider>
  );
};

export default Dashboard;
