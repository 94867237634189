import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const BarChart = ({ data }) => {
  return (
    <div style={{ height: '686px', width: '100%' }}> {/* Set height explicitly */}
      <Bar 
        data={data} 
        options={{
          responsive: true,
          maintainAspectRatio: false, // False means the chart can stretch, remove this if unnecessary
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Bar Chart Example',
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }} 
      />
    </div>
  );
};

export default BarChart;
